.LicenseNumber {
  font-weight: bold;
  font-size: calc(10px + 3vmin);
}

.ManualCheck {
  margin-top: 40px;
  border: none;
  background: none;
  text-decoration-line: underline;
  font-size: calc(10px + 1vmin);
}
.ManualCheck:hover {
  color: #999999;
}
.ManualCheck:focus {
  outline: none;
}
