body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.brand {
  margin-bottom: 3rem;
}
.brand-link {
  display: block;
  text-decoration: none;
}
.layout-logo {
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
}
.layout-title {
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 33px;
  font-family: Acumin Pro, Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
}

.h07_h1 {
  max-width: 48rem;
  margin: 0 auto 17px;
  font-family: acumin-pro, sans-serif;
  color: rgba(0, 9, 65, 0.9);
  font-size: 48px;
  line-height: 52px;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.po2_h2 {
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem;
  font-family: acumin-pro, sans-serif;
  color: #0a1f44;
  font-size: 38px;
  line-height: 40px;
  font-weight: 500;
}
.c07_h3 {
  max-width: 24rem;
  margin-top: 0;
  color: rgba(0, 9, 65, 0.9);
  font-size: 38px;
  line-height: 42px;
}
.h07_text {
  max-width: 24rem;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.2px;
}
.error_text {
  max-width: 24rem;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #ff0000;
}

.buttonPrimary {
  position: relative;
  min-height: 50px;
  min-width: 140px;
  margin-top: 15px;
  padding: 11px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(23, 48, 73, 0.18);
  border-radius: 5px;
  background-color: #2c4cce;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: #fff;
  font-size: 14px;
  font-family: Lato, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.form-input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: #2d3748;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  overflow: visible;
  text-overflow: ellipsis;
  text-align: center;
}
.form-input::before {
  box-sizing: border-box;
}
.form-input::after {
  box-sizing: border-box;
}
.form-input::selection {
  color: #fff;
  background: #1e77e6;
}
